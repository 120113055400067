<template>
<!-- aegis-first-screen-timing -->

  <div
    aegis-first-screen-timing
    :class="{
      hide: !visible,
    }"
   class="aegis-first-screen-timing">
    <ContentLoader
      v-if="pathname?.includes('/package-activity/phone-card/index')" viewBox="0 0 375 1000"
    >
      <rect x="0" y="0" width="375" height="400" />
      <rect x="8" y="400" rx="16" ry="16" width="232" height="30" />
      <rect x="20" y="450" rx="6" ry="6" width="335" height="38" />
      <rect x="20" y="500" rx="6" ry="6" width="335" height="38" />
      <rect x="20" y="550" rx="6" ry="6" width="335" height="38" />
      <rect x="53" y="609" rx="20" ry="20" width="270" height="40" />
      <rect x="36" y="667" rx="0" ry="0" width="303" height="68" />
      <rect x="0" y="780" width="375" height="375" />
      <Placeholder />
    </ContentLoader>
    <ContentLoader
      v-if="pathname?.includes('/pages/index/index')" viewBox="0 0 375 1000"
    >
      <rect x="12" y="12" rx="6" ry="6" width="351" height="138" />
      <rect x="12" y="162" rx="6" ry="6" width="351" height="138" />
      <rect x="12" y="312" rx="6" ry="6" width="351" height="138" />
      <rect x="12" y="462" rx="6" ry="6" width="351" height="138" />
      <rect x="12" y="612" rx="6" ry="6" width="351" height="138" />
      <rect x="12" y="762" rx="6" ry="6" width="351" height="138" />
      <rect x="12" y="912" rx="6" ry="6" width="351" height="138" />
      <Placeholder />
    </ContentLoader>
    <ContentLoader
      v-if="pathname?.includes('/package-activity/flow-package/index')" viewBox="0 0 375 1000"
    >
      <rect x="0" y="0" width="375" height="416" />
      <rect x="0" y="420" width="375" height="30" />
      <rect x="80" y="465" width="235" height="25" />
      <rect x="20" y="528" rx="12" ry="12" width="340" height="56" />
      <rect x="20" y="608" rx="12" ry="12" width="216" height="56" />
      <rect x="244" y="608" rx="12" ry="12" width="116" height="56" />
      <rect x="62" y="696" rx="12" ry="12" width="250" height="40" />
      <rect x="17" y="756" rx="12" ry="12" width="340" height="80" />
      <Placeholder />
    </ContentLoader>
  </div>
</template>

<script setup lang='ts'>
import { ref } from 'vue'
import { ContentLoader } from 'vue-content-loader'
import Placeholder from './placeholder.vue'

defineProps<{
  visible?: boolean
}>()

const pathname = ref(window.location.pathname)
</script>

<style scoped>
.hide {
  position: absolute;
  z-index: -999;
  height: 10px;
}
</style>
